@import '../../styles/colors';

.forgot-password-wrap{
    width: 30.7vw;
    min-width: 442px;
    margin: auto;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 480px) {
        width: -webkit-fill-available;
        min-width: unset;
        padding: 0 16px;
    }
    .content-wrap{
        .subHeading{
            color: $tertiary-light-gray;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.1px;
            margin-top: 18px;
            margin-bottom: 96px;
        }
        .loginBtn{
            margin-top: 36px;
        }
        .back-button-wrap{
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            margin-top: 28px;
            color: var(--Primary-Color, #0B1F3E);
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.25px;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }
}