 @import '../../styles/colors';
 .sidebar {
  background: $primary-white;
  color: $primary-gray;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding-top: 18px;
  padding-bottom: 18px;
  .sidebar-item {
    padding: 10px;
    cursor: pointer;
    display: flex;
    height: 40px;
    align-items: center;

    .image-icon{
      margin-right: 14px;
    }
    
  }

  .sidebar-item:hover {
    background-color: $dark-gray;
    color: var(--Primary-Color, $primary-blue);
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    border-left: 6px solid $primary-blue;
  }

  .selected {
    background-color: $dark-gray;
    color: var(--Primary-Color, $primary-blue);
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    border-left: 6px solid $primary-blue;
  }
}
@media (max-width: 700px){
  .sidebar{
    display: none;
  }
}