.reset-password-wrap {
    width: 30.7vw;
    min-width: 442px;
    margin: auto;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 480px) {
        width: -webkit-fill-available;
        min-width: unset;
        padding: 0 16px;
    }

    .content-wrap {
        margin-top: 96px;

        .confirmPassword-wrap {
            margin-top: 28px;
        }

        .loginBtn {
            margin-top: 36px;
        }

        .back-button-wrap {
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            margin-top: 28px;
            color: var(--Primary-Color, #0B1F3E);
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.25px;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }

    .icons-wrap {
        display: flex;
        cursor: pointer;
    }
}

.retry_modal {
    .MuiDialog-container {
        width: 100%;

        .MuiPaper-root {
            width: 400px;
        }

        @media (max-width: 432px) {
            .MuiPaper-root {
                width: 100%;
            }
        }
    }
}

.retry_container {
    .retry_icon {
        margin-top: 13px;
        margin-bottom: 20px;
        text-align: center;
    }

    .retry_heading {
        color: #252525;
        text-align: center;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 14px;
    }

    .retry_subheading {
        color: #929292;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.1px;
        margin-bottom: 28px;

    }

    .retry_footer {
        text-align: center;
    }
}