@import '../../styles/colors';

.single-select{
  width: 100%;
  .custom-Select {
    border-radius: 0px !important;
    margin-bottom: 10px !important;
    margin-top: 5px !important;
    width: 100% !important;
    background-color: $primary-white !important;
    .MuiSelect-select{
      font-family: 'Poppins', sans-serif !important;
      text-align: initial !important;
      padding: 15.5px 9px !important;
      font-weight: 500 !important;
      font-size: 16px !important;
    }
    input{
      border: none !important;
    }
    .placeholder{
      color: rgba(43, 39, 36, 0.5);
    }
  }
  .custom-Select:hover .MuiOutlinedInput-notchedOutline{
	}
  .Mui-focused .MuiOutlinedInput-notchedOutline{
  }
  .field-error{
    .MuiInputBase-root {
      border: 1px solid $primary-red !important;
      border-radius: 6px !important;
    }
  }
  .input-Label_text {
    font-weight: 400;
      text-align: left;
      font-size: 13px;
    line-height: 16px;
  }
  .textfield-error{
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: $primary-red !important;
    display: flex;
    div{
      img{
        width: 16px;
      }
      position: relative;
      span{
        position: relative;
        top: -4px;
      }
    }
  }
  
}
.info-icon2{
  margin-left: 5px;
  cursor: pointer;
  margin-bottom: -7px;
  position: relative;
  bottom: 2px;
}
.MuiPaper-root{
  margin-top: 4px !important;
  border: 1px solid $secondary-gray !important;
  border-radius: 4px !important;
  .MuiList-root{
    
    .MuiMenuItem-root{
      font-family: 'Poppins', sans-serif !important;
      color: $tertiary-light-gray !important;
    }
    .MuiMenuItem-root:hover{
      background-color: $secondary-white !important;
      color: $primary-black !important;
    }
    .MuiMenuItem-root.Mui-selected{
      background-color: $secondary-white !important;
      color: $primary-black !important;
    }
  }
}

.important{
  color: $primary-red;
  font-weight: 400;
}