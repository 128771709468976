@import '../../styles/colors';
.date-picker-wrap {
  display: flex;
  flex-direction: column;
  .MuiFormLabel-root {
    color: $primary-grey;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px; /* 100% */
    text-transform: capitalize;
    margin-bottom: 12px;
    .required-asterik{
      color: $primary-red !important;
      font-weight: '400' !important;
    }
  }
  .MuiFormControl-root{
    .MuiOutlinedInput-notchedOutline{
      border : 1px solid $primary-light-gray !important;
    }
  }
}
