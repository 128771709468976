@import "../../styles/colors";

.profile-wrap {
  display: flex;
  flex-direction: column;
  background: $primary-white;
  box-shadow: 0px 0px 2px 0px $shadow-color;

  .profile-section {
    border-bottom: 1px solid $normal-white;
    background: $dark-gray;
    height: 79px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    justify-content: center;

    .profile {
      position: absolute;
      top: 17px;
      width: 80px;
      height: 80px;
      border-radius: 80px;
      border: 1px solid $forth-gray;
      background: $secondary-white;
      overflow: hidden;

      .profileImage {
        width: 80px;
        height: 80px;
      }
    }
  }

  .profile-section-content {
    background: $primary-white;
    box-shadow: 0px 0px 2px 0px $shadow-color;
    height: 85px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .profile-name {
      color: var(--main-color, $primary-black);
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 8px;
      margin-top: 8px;
    }

    .profile-mail {
      color: $primary-gray;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      /* 100% */
    }
  }

  .view-no {
    color: $tertiary-gray;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    /* 85.714% */
    padding: 14px 17px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-top: 1px solid $grey-border;

    .title {
      cursor: pointer;
    }

    .number {
      color: var(--Primary-Color, $primary-blue);
      font-size: 14px;
      font-weight: 500;
      line-height: 12px;
      /* 85.714% */
      margin-left: auto;
    }
  }

  @media (max-width: 1100px) {
    .view-no {
      font-size: 10px;

      .number {
        font-size: 10px;
      }
    }
  }
}

@media (max-width: 700px) {
  // .profile-wrap {
  //   display: none;
  // }
}

.viewedProfileDialog {
  .viewed-profile-wrap {
    padding: 0px !important;

    .heading {
      text-transform: unset !important;
    }

    .content-wrap {
      width: 480px;
      overflow-y: auto;
      max-height: 55vh;

      @media screen and (max-width: 768px) {
        width: 74vw;
      }

      @media screen and (max-width: 480px) {
        width: 100%;
        overflow-x: hidden;
      }

      .card-wrap {
        background: $secondary-white;
        display: flex;
        align-items: center;
        padding: 8px;

        .profile-pic-wrap {
          width: 42px;
          height: 42px;
          // border-radius: 51%;
          margin-right: 16px;
          // overflow: hidden;

          @media screen and (max-width: 480px) {
            margin-right: 8px;
          }

          .profileImage {
            width: 42px;
            height: 42px;
          }
        }

        .right-wrap {
          width: 100%;

          .name {
            color: #252525;
            font-size: 16px;
            font-weight: 500;
            width: 90%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media screen and (max-width: 480px) {
              font-size: 14px;
            }
          }

          .mail {
            color: $primary-black;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;

            @media screen and (max-width: 480px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}