@import "../../styles/colors";

.login-content {
  display: flex;
  align-items: center;
  flex-direction: column;

  .login-content-wrap {
    width: 30vw;
    min-width: 442px;
    margin-top: 50px;
    padding-bottom: 30px !important;

    @media screen and (max-width: 480px) {
      width: -webkit-fill-available;
      padding: 0px 15px;
      min-width: unset;
    }

    .forgot-password {
      display: flex;
      justify-content: flex-end;
      color: var(--Primary-Color, $primary-blue);
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
      /* 131.25% */
      text-decoration-line: underline;
    }

    .loginBtn {
      margin-top: 36px;
    }

    .orDivider {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      color: $secondary-light-black;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      text-transform: uppercase;
      margin: 24px auto;
      margin-bottom: 38px;

      .dividerLine {
        background: $secondary-light-black;
        width: 45%;
        height: 1px;
      }
    }
  }

  .footer {
    margin-top: 41px !important;

    .sign-up {
      margin-left: 5px;
    }
  }

  .otp-content-footer-wrap {
    color: $primary-gray;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 68px;
    display: flex;
    flex-direction: column;

    .supportMail {
      color: $primary-black;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}

.icons-wrap {
  display: flex;
  cursor: pointer;
}