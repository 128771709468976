@import "../../styles/colors";

.drawer-container {
  padding: 40px 20px 57px 20px;
  height: 100%;

  .drawer-profile-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .profile {
      width: 56px;
      height: 56px;
      border-radius: 80px;
      border: 1px solid $forth-gray;
      background: $secondary-white;
      overflow: hidden;

      .profileImage {
        width: 56px;
        height: 56px;
      }
    }

    .cross-icon {
      position: absolute;
      right: 0px;
      cursor: pointer;
    }

    .user-detail {
      position: relative;

      .user-name {
        margin-top: 24px;
        color: var(--main-color, $primary-black);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        /* 100% */
      }

      .user-email {
        margin-top: 9px;
        color: $primary-gray;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        /* 100% */
      }

      .profile-arrow {
        position: absolute;
        right: 0;
        top: 50%;
        cursor: pointer;
      }
    }

    .about-section {
      color: $primary-black;
      font-size: 14px;
      font-weight: 500;
      line-height: 12px;
      margin-bottom: 16px;
      margin-top: auto;
      cursor: pointer;
    }

    .drawer-request {
      .view-no {
        color: $primary-black;
        font-size: 14px;
        font-weight: 500;
        line-height: 12px;
        /* 85.714% */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        margin-top: 36px;
        width: 300px;

        .title {
          cursor: pointer;
        }

        .number {
          margin-left: auto;
          cursor: pointer;
        }
      }
    }

    .drawer-footer {
      margin-top: auto;

      .log-out {
        color: $primary-red;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        /* 100% */
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }
}