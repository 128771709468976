@import "../../styles/colors";

.home-wrap {
  background: $primary-white;
  padding: 28px 32px 22px 32px;
  display: flex;
  flex-direction: column;
  gap: 18px;

  .home-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;

    .body-heading {
      color: $primary-black;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      /* 133.333% */
    }

    .body-content {
      color: $primary-black;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      /* 100% */
    }

    .body-detail {
      color: $primary-blue;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      /* 100% */
      text-decoration-line: underline;
    }
  }

  .home-right {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    justify-content: flex-start;

    .home-right-content {
      color: $primary-black;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      /* 100% */
    }

    .home-right-no {
      color: $primary-black;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      /* 100% */
    }
  }

  @media (max-width: 700px) {
    .home-right {
      display: none;
    }
  }
}

.responsive-profile {
  display: none;

  @media (max-width: 700px) {
    display: block;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    width: calc(100% - 32px);
    max-width: 418px;
  }
}

.viewedProfileDialog {
  .incomplete-profile-wrap {
    .heading {
      text-transform: unset !important;

      @media screen and (max-width: 480px) {
        font-size: 16px !important;
      }
    }

    .subHeading {
      color: $primary-gray;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.1px;
    }

    .button-wrap {
      display: flex;
      justify-content: space-evenly;
    }
  }
}

@media (max-width: 700px) {
  .viewedProfileDialog {
    .incomplete-profile-wrap {
      .button-wrap {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}