@import '../../styles/colors';


.customer-dashboard-wrap {
    max-height: calc(100vh - 76px);
    padding-top: 30px;
    overflow-y: auto;

    @media screen and (max-width: 768px) {
        padding-top: 0px;
        padding: 0px;
        // height: calc(100vh - 81px);
    }

    .customer-dashboard-container {
        width: 90vw;
        margin: auto;
        padding-bottom: 30px;

        @media screen and (max-width: 768px) {
            width: -webkit-fill-available;
            padding-bottom: 20px;
            padding-top: 0px;
            margin: 12px;
            // height: calc(100vh - 81px);
        }
    }

    .profile-detail-wrap {
        display: flex;
        padding: 42px;
        border-bottom: 1px solid $forth-gray;
        background: $sixth_light_gray;

        @media screen and (max-width: 1000px) {
            padding: 20px;
        }

        @media screen and (max-width: 768px) {
            display: none;
        }

        .left-content-wrap {
            display: flex;
            flex: 1;

            .profile-pic-wrap {
                display: flex;
                border-radius: 146px;
                border: 4px solid $tertiary-grey-border;
                position: relative;
                width: 120px;
                height: 120px;

                .profilePic {
                    width: -webkit-fill-available;
                    border-radius: 146px;
                }

                .edit-icon-image {
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                    cursor: pointer;
                }

                .fileUploadInput {
                    display: none;
                }
            }

            .user-detail-wrap {
                margin-left: 26px;
                text-align: left;

                .name {
                    color: $primary-black;
                    font-size: 28px;
                    font-weight: 600;
                }

                .email-wrap {
                    display: flex;
                    color: $primary-black;
                    font-size: 16px;
                    font-weight: 400;
                    margin: 10px 0px;
                }

                .location-wrap {
                    display: flex;
                    color: $primary-black;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }

        .right-content-wrap {
            flex: 1;
            text-align: left;

            .organizationName {
                color: $primary-black;
                font-size: 24px;
                font-weight: 600;
            }
        }
    }

    .content-wrap-main {
        @media screen and (max-width: 768px) {
            position: relative;

            .tabs-wrap-main {
                position: sticky;
                top: 0px;
                background: #ffffff;
                z-index: 2;
            }

            .card-wrap-main {
                padding-top: 0px;
                z-index: 1;
            }
        }
    }

    .content-wrap {
        background: $primary-white;

        @media screen and (max-width: 480px) {
            // height: calc(100vh - 80px);
        }

        .tabs-wrap .MuiTabs-flexContainer .MuiTab-root {
            @media screen and (max-width: 1000px) {
                padding: 21px 20px;
            }

            @media screen and (max-width: 480px) {
                padding: 21px 10px;
            }
        }

        .cards-wrap {
            // max-height: 58vh;
            overflow-y: auto;

            .card-wrap {
                display: flex;
                padding: 24px 32px;
                align-items: center;
                border-bottom: 1px solid $grey-border;

                @media screen and (max-width: 768px) {
                    align-items: flex-start;
                }

                @media screen and (max-width: 480px) {
                    padding: 16px 12px;
                }

                .profilePic-wrap {
                    // width: 76px;
                    // height: 76px;
                    width: 5vw;
                    height: 5vw;
                    display: flex;
                    border-radius: 146px;
                    border: 2px solid $secondary-grey-border;
                    margin-right: 18px;
                    overflow: hidden;

                    @media screen and (max-width: 768px) {
                        width: 8vw;
                        height: 8vw;
                    }

                    @media screen and (max-width: 480px) {
                        width: 42px;
                        height: 42px;
                        margin-right: 8px;
                    }

                    .profileImage {
                        width: inherit;
                    }
                }

                .right-content-wrap {
                    display: flex;

                    @media screen and (max-width: 768px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .name-wrap {
                        text-align: left;
                        max-width: 350px;
                        width: 350px;
                        margin-right: 10px;

                        @media screen and (max-width: 900px) {
                            // width: 100%;
                            width: 300px;
                        }

                        @media screen and (max-width: 768px) {
                            margin-right: 0px;
                            margin-bottom: 7px;
                            width: 100%;
                        }

                        .name {
                            color: $primary-black;
                            font-size: 24px;
                            font-weight: 600;
                            line-height: 24px;
                            margin-bottom: 8px;

                            @media screen and (max-width: 480px) {
                                font-size: 14px;
                            }
                        }

                        .email {
                            color: $primary-black;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 14px;

                            @media screen and (max-width: 480px) {
                                font-size: 12px;
                            }
                        }
                    }

                    .button-wrap {
                        @media screen and (max-width: 768px) {
                            display: none;
                        }
                    }

                    .status-wrap {
                        margin-left: 56px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        @media screen and (max-width: 900px) {
                            margin-left: 24px;
                        }

                        @media screen and (max-width: 768px) {
                            margin-left: 0px;
                        }

                        .verify-tag-wrap {
                            display: flex;
                            align-items: center;
                            border-radius: 12px;
                            background: $primary-green;
                            color: $primary-white;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 12px;
                            padding: 5px 12px;
                            margin-top: 8px;

                            .filterImage {
                                filter: brightness(2);
                            }
                        }
                    }
                }
            }

            .pending-card-wrap {
                .right-content-wrap {
                    .name-wrap {
                        margin-right: 5.2vw;

                        @media screen and (max-width: 768px) {
                            margin-bottom: 13px;
                        }
                    }

                    .status-wrap {
                        margin-left: 3.8vw;

                        @media screen and (max-width: 768px) {
                            margin-left: 0;
                        }

                        .pending-tag-wrap {
                            background: $primary-yellow;

                            @media screen and (max-width: 768px) {
                                margin-top: 0px;
                            }
                        }
                    }

                    .footer-wrap {
                        display: flex;

                        @media screen and (max-width: 768px) {
                            flex-direction: row-reverse;
                        }

                        .status-wrap {
                            @media screen and (max-width: 768px) {
                                margin-right: 20px;
                            }
                        }
                    }
                }
            }

            .home-content-wrap {
                min-height: 58vh;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .no-data {
                    margin-top: 40px;
                    margin-bottom: 20px;
                    color: $primary-black;
                    font-size: 16px;
                    font-weight: 600;
                }

                .no-content {
                    color: $primary-gray;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
}


.organizationDialog {
    .organization-popup-wrap {
        min-width: 450px;
        padding: 0px 20px;

        @media screen and (max-width: 768px) {
            min-width: auto;
            padding: 0px;
        }

        .dialogHeading {
            color: $primary-black;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            margin-top: 5px;
            margin-bottom: 15px;

            @media screen and (max-width: 480px) {
                font-size: 16px;
            }
        }

        .userType-wrap {
            margin-bottom: 15px;

            .MuiFormGroup-root .radio-options {
                margin-right: 40px;

                @media screen and (max-width: 768px) {
                    margin-right: 0px;
                }
            }
        }

        .firstname-field-wrap {
            margin-bottom: 20px;
        }
    }
}