@import '../../styles/colors';

.status-wrap {


    .pending-tag-wrap {
        background: $primary-yellow !important;

        @media screen and (max-width: 768px) {
            margin-top: 0px;
        }
    }
}