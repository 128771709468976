@import '../../styles/colors';
.MuiFormGroup-root {
  .MuiFormControlLabel-root {
    .MuiTypography-root {
      color: var(--main-color, $primary-black);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    .MuiButtonBase-root{
        color: #CCC;
    }
    .Mui-checked {
        color: var(--Primary-Color, $primary-blue);;
    }
  }
  .radio-options{
    margin-right: 15px;
  }
}
