@import '../../styles/colors';

.otp-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 480px){
        padding: 0px 20px;
    }
    .otp-content-wrap{
        width: 30vw;
        min-width: 442px;
        margin-top: 34px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 480px){
            width: -webkit-fill-available;
            min-width: unset;
        }
        .subheading{
            color: $tertiary-light-gray;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.1px;
        }
        .email-wrap{
            color: var(--main-color, $primary-black);
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.1px;
            .change-btn{
                color: var(--Primary-Color, $primary-blue);
                font-size: 16px;
                font-weight: 600;
                text-decoration-line: underline;
                cursor: pointer;
            }
        }
        .field-wrap{
            margin-top: 92px;
            margin-bottom: 36px;
            @media screen and (max-width: 480px){
                width: 100%;
            }
        }
        .button-wrap{
            width: 100%;
        }
    }
    .otp-content-footer-wrap{
        color: $primary-gray;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 68px;
        display: flex;
        flex-direction: column;
        .supportMail{
            color: $primary-black;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }
}