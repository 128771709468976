@import "../../styles/colors";

.no-data-wrap {
  padding: 0px;
  margin: 30px 0px;
  gap: 0px;
  min-height: 442px;
  background: $dark-gray;
  display: flex;
  justify-content: center;
  align-items: center;


  .home-content-wrap {
    width: fit-content;
    align-self: center;

    @media screen and (max-width: 768px) {
      width: auto;
    }

    .no-data {
      color: var(--main-color, $primary-black);
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      /* 100% */
      margin-top: 44px;
    }

    .no-content {
      color: $primary-gray;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      /* 150% */
      margin: 18px auto 0 auto;
      padding: 0 10px;
      max-width: 360px;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      grid-gap: 22px;
      margin-top: 27px;
    }
  }
}

@media (max-width: 700px) {
  .no-data-wrap {
    margin: 0px 0px;
    height: calc(100vh - 200px);

    .home-content-wrap {

      .buttons {
        flex-direction: column;
      }
    }
  }
}