@import "../styles/colors";

.layout-wrap {
  position: relative;

  .layout-header {
    position: sticky;
    width: -webkit-fill-available;
    top: 0;
    left: 0;
    border-bottom: 1px solid $primary-light-black;
    background: $primary-blue;
    text-align: left;
    padding: 25.5px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;

    @media screen and (max-width: 800px) {
      padding: 25.5px 25px;
    }

    @media screen and (max-width: 480px) {
      padding: 22px 15px;

      .logoImage {
        width: 153px;

        @media screen and (max-width: 350px) {
          width: 130px;
        }
      }
    }

    .search-layout {
      margin-right: auto;
      margin-left: 30px;
      position: relative;

      .search-lens {
        display: none;
      }

      .MuiInputBase-input {
        padding-right: 32px;
      }
    }

    @media (max-width: 700px) {
      .search-layout-wrap {
        display: flex;
        justify-content: center;
        position: relative;

        .back-arrow {
          margin-right: 20px;
        }

        .search-user {
          width: 250px;
          display: flex;
          justify-content: center;
          align-items: center;

          .css-1qds4ov {
            width: 100%;
            max-width: 500px;
          }

          .MuiInputBase-input {
            padding-right: 32px;
          }
        }
      }

      .search-layout {
        margin-left: auto;
        margin-right: 0px;

        .search-box {
          display: none;
        }

        .search-lens {
          display: flex;
          width: 35px;
        }
      }
    }

    .logo-wrap {
      display: flex;

      .logoImage {
        cursor: pointer;
      }
    }

    .buttons-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .userProfile {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .image-avatar {
        @media (max-width: 700px) {
          display: none;
        }
      }

      .user-image {
        cursor: pointer;
      }

      .user-image-drawer {
        @media (min-width: 700px) {
          display: none;
        }
      }

      .user-profile-name {
        display: flex;

        .header-user-name {
          color: var(--White, $primary-white);
          text-align: right;
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;

          /* 100% */
          @media screen and (max-width: 480px) {
            display: none;
          }
        }

        @media (max-width: 700px) {
          display: none;
        }
      }
    }

    .listView {
      position: absolute;
      background: $primary-white;
      box-shadow: 0px 0px 2px 0px rgba(40, 41, 61, 0.14);
      width: 261px;
      right: 26px;
      top: 72px;

      .listView-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .list-item {
        display: flex;
        justify-content: space-between;
        padding: 21px 16px 21px 16px;
        color: var(--main-color, $primary-black);
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
      }

      .danger {
        color: $primary-red;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .dashboard-header {
    display: flex;
    justify-content: flex-start;
  }

  .layout-header-dashboard {
    padding: 17.5px 80px;

    @media screen and (max-width: 768px) {
      padding: 17.5px 25px;
    }

    @media screen and (max-width: 480px) {
      padding: 9.5px 15px;

      .logoImage {
        width: 153px;

        @media screen and (max-width: 350px) {
          width: 130px;
        }
      }
    }
  }

  .layout-content-scroll {
    max-height: calc(100vh - 76px);
    overflow-y: hidden;

    @media screen and (max-width: 480px) {
      max-height: calc(100vh - 60px);
    }
  }

  .layout-content-wrap {
    background: $secondary-white;
    // padding-top: 76px;
    min-height: calc(100vh - 76px);

    @media screen and (max-width: 480px) {
      padding-top: 0px;
      min-height: calc(100vh - 60px);
    }

    .content-heading {
      color: $primary-black;
      font-size: 28px;
      font-weight: 600;
      margin-top: 58px;
      text-transform: capitalize;

      @media screen and (max-width: 480px) {
        font-size: 20px;
      }
    }

    .content-footer-wrap {
      color: $primary-black;
      font-size: 16px;
      font-weight: 400;
      margin-top: 82px;

      .redirectionBtn {
        color: $primary-blue;
        font-size: 16px;
        font-weight: 600;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }

  .layout-footer-wrap {
    background: $forth_light-gray;
    color: $tertiary-gray;
    font-size: 12px;
    font-weight: 400;
    padding: 11px 0px;
    position: fixed;
    bottom: 0;
    width: 100%;
    // @media screen and (max-width: 520px) {
    //   font-size: 12px;
    // }
  }
}

.logoutDialog {
  .logout-wrap {
    width: 400px;
    padding: 0px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 768px) {
      width: auto;
    }

    .heading {
      text-transform: unset !important;
    }

    .subHeading {
      color: $tertiary-light-gray;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.1px;
    }
  }
}

.search-wrap {
  max-width: 400px;
  width: 100%;
  max-height: 300px;
  background-color: $primary-white;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 16;
  left: 0;
  margin-top: 20px;
  padding: 22px 20px 22px 20px;
  overflow-x: auto;

  .search-content-wrap {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 20px;

    .search-detail-wrap {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      .search-name {
        color: $primary-black;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        /* 100% */
      }

      .search_source_tag {
        margin-left: auto;
      }

      .search_source_tag_text {
        color: #fff;
        background-color: #0CAD39;
        padding: 2px 8px 3px 8px;
        border-radius: 12px;
        margin-left: 12px;
        font-size: 11px;
        line-height: 11px;
      }

      .search-email {
        color: $primary-gray;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        /* 100% */
      }
    }
  }

  @media (max-width: 700px) {
    max-width: 400px;
    width: 100%;
    margin-top: 44px;
  }
}