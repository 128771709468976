@import '../../styles/colors';
.otpField-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    > div {
        @media screen and (max-width: 480px) {
            width: 100%;
            justify-content: space-between;
        }
       > input{
            width: 100% !important;
            padding: 14.5px 16px;
            margin: 0px 12px;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            border: 1px solid $primary-light-gray;
            @media screen and (max-width: 480px) {
                width: 16px !important;
                padding: 8.5px 12px;
                margin: 0px auto;
            }
        }
      }
    .otp-footer-wrap{
        margin-top: 48px !important;    
        text-align: center;  
        .redirectionBtn{
            color: $primary-blue;
            font-size: 16px;
            font-weight: 600;
            text-decoration-line: underline;
            cursor: pointer;
        }  
    }
}