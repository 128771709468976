@import "../../styles/colors";

.employment-page-wrap {
  background: $primary-white;
  padding: 28px 32px 22px 32px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-height: 600px;
  height: 100%;

  .employment-header {
    display: flex;
    justify-content: space-between;

    .MuiButtonBase-root {
      width: 200px;
    }
  }

  .employment-body-wrap {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  @media (max-width: 800px) {
    .employment-header {
      .MuiButtonBase-root {
        width: 150px;
        height: 36px;
        font-size: 12px;
      }

      .emp-heading {
        font-size: 20px;
        display: flex;
        align-items: center;
      }
    }
  }

  @media (max-width: 700px) {
    max-height: 100%;
    padding: 12px 16px 0px 16px;
  }
}

.employmentDialog {
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiDialog-container {
    .MuiPaper-root {
      // height: 720px;
    }
  }

  .employment-modal {
    display: flex;
    flex-direction: column;

    .modal-title {
      display: flex;
      justify-content: center;
      color: $primary-black;
      font-size: 20px;
      font-weight: 600;
      padding: 10px;
      line-height: 20px;
      /* 100% */
    }

    .employment-head {
      display: flex;
      justify-content: center;
    }

    .employment-modal-wrap {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .bottom-buttons {
        display: flex;
        gap: 10px;
      }

      .input-Label_text {
        color: $primary-grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        /* 100% */
      }

      .MuiFormControl-root {
        .MuiInputBase-root {
          .MuiInputBase-input {
            color: $primary-black;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            /* 100% */
          }
        }
      }

      .employment-checkbox-wrap {
        margin-bottom: -5px;

        .employment-checkbox {
          color: var(--main-color, $primary-black);
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          /* 150% */
        }
      }

      .date-picker {
        display: flex;
        flex-direction: row;
        gap: 14px;
      }
    }
  }
}