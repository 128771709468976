@import '../../styles/colors';
.modal-c-popup {
  background: rgba(26, 26, 26, 0.5);
  .modal-content {
    padding: 15px;
    .modal-content-heading {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      display: flex;
      justify-content: center;
    }
    .modal-content-footer {
      display: flex;
      justify-content: space-between;
      gap: 15px;
    }
  }
}