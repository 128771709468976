@import '../../styles/colors';

.myProfile-wrap {
    padding: 24px 80px;

    @media screen and (max-width: 800px) {
        padding: 24px 25px;
    }

    @media screen and (max-width: 520px) {
        padding: 24px 15px;
    }

    .mobile-header {
        display: none;

        @media screen and (max-width: 520px) {
            display: block;
            text-align: left;
            color: $primary-black;
            font-size: 20px;
            font-weight: 600;
            margin-top: 22px;
        }
    }

    .myProfile-content-wrap {
        min-height: calc(100vh - 180px);
        background: $sixth_light_gray;
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @media screen and (max-width: 520px) {
            min-height: calc(100vh - 205px);
            margin-top: 56px;
        }

        .org_container {
            display: none;
        }

        @media screen and (min-width: 850px) {
            .org_container {
                display: block;
                margin-right: 66px;
                margin-bottom: 36px;
                width: fit-content;
                align-self: flex-end;
            }

            .org_heading {
                color: #252525;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                text-align: start;
                margin-bottom: 8px;
            }

            .org_name {
                text-align: start;
                color: #252525;
                font-family: Poppins;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }
        }
    }

    .details-wrap {
        border-top: 1px solid $forth-gray;
        background: $primary-white;
        min-height: calc(100vh - 500px);
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 31px 66px;

        @media screen and (max-width: 800px) {
            padding: 31px 20px;
        }

        @media screen and (max-width: 680px) {
            flex-direction: column;
        }

        @media screen and (max-width: 520px) {
            min-height: calc(100vh - 320px);
            padding-bottom: 0px;
        }

        .profile-pic-wrap {
            position: absolute;
            top: -140px;
            left: 60px;
            border: 4px solid $border-light-gray;
            border-radius: 146px;
            width: 176px;
            height: 176px;
            background: $secondary-white;

            @media screen and (max-width: 800px) {
                left: 20px;
            }

            @media screen and (max-width: 520px) {
                width: 95px;
                height: 95px;
                top: -50px;
            }

            .profile-image {
                width: -webkit-fill-available;
                height: -webkit-fill-available;
                border-radius: 146px;
            }

            .edit-button-wrap {
                position: absolute;
                right: 0;
                bottom: -5px;
                cursor: pointer;

                .edit-icon-image {
                    @media screen and (max-width: 520px) {
                        width: 25px;
                    }
                }

                .fileUploadInput {
                    display: none;

                    @media screen and (max-width: 520px) {
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }

        .left-content-wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .username-wrap {
                margin-top: 40px;
                color: $primary-black;
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px;
                text-align: left;

                @media screen and (max-width: 480px) {
                    font-size: 20px;
                }
            }

            .email-wrap {
                display: flex;
                margin-top: 24px;
                margin-bottom: 16px;
                color: $primary-black;
                font-size: 16px;
                font-weight: 400;
                text-align: left;

                @media screen and (max-width: 480px) {
                    font-size: 14px;
                    overflow-wrap: anywhere;
                }
            }

            .location-wrap {
                display: flex;
                color: $primary-black;
                font-size: 16px;
                font-weight: 400;
                text-align: left;

                @media screen and (max-width: 480px) {
                    font-size: 14px;
                }
            }

            .org_res_container {
                display: none;

                @media screen and (max-width: 849px) {
                    display: block;
                    margin-top: 24px;
                    margin-bottom: 24px;

                    .org_res_heading {
                        color: #252525;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        text-align: start;
                        margin-bottom: 8px;
                    }

                    .org_res_name {
                        text-align: start;
                        color: #252525;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;
                    }

                }
            }
        }

        .right-content-wrap {
            gap: 30px;
            display: flex;
            align-items: flex-start;

            @media screen and (max-width: 940px) {
                flex-direction: column;
            }

            @media screen and (max-width: 680px) {
                flex-direction: column;
                justify-content: space-around;
            }
        }
    }
}

.editProfileImageDialog {
    width: 100%;

    @media screen and (max-width: 630px) {
        .MuiPaper-root {
            width: 100%;
        }
    }
}

.profile-pic-edit-modal-content {
    margin-top: 24px;
    width: 540px;


    @media screen and (max-width: 630px) {
        width: 100%;
    }

    .profile-pic-edit-modal {
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;

        .profile-image {
            border: 4px solid rgba(11, 31, 62, 0.25);
            width: 144px;
            height: 144px;
            flex-shrink: 0;
            border-radius: 50%;
        }

        .edit-fileUploadInput {
            display: none;
        }
    }

    .photo-edit-button {
        margin-top: 48px;
        display: flex;
        justify-content: center;

        @media screen and (max-width: 640px) {
            flex-direction: column;
            align-items: center;
        }
    }
}

.editProfileDialog {

    .edit-profile-wrap,
    .edit-profile-wrap-incomplete {
        padding: 0px 25px;

        @media screen and (max-width: 520px) {
            padding: 0px;
        }

        .profile-pic-wrap {
            text-align: center;
        }

        .heading {
            color: var(--main-color, #252525);
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            text-transform: capitalize;
        }

        .name-wrap {
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 520px) {
                flex-direction: column;
            }

            .firstname-field-wrap {
                margin-right: 18px;

                @media screen and (max-width: 520px) {
                    margin-right: 0px;
                    margin-bottom: 24px;
                }
            }
        }
    }

    .edit-profile-wrap-incomplete {
        padding: 0px 16px;
    }

    .userType-wrap {
        margin-bottom: 15px;

        .MuiFormGroup-root .radio-options {
            margin-right: 40px;

            @media screen and (max-width: 768px) {
                margin-right: 0px;
            }
        }
    }

    @media screen and (max-width: 700px) {
        .MuiDialog-container {
            width: 100%;

            .MuiDialog-paper {
                width: 100%;
            }
        }
    }

}

.changePasswordDialog {
    .subHeading {
        color: $primary-gray;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.1px;
        padding: 0px 65px;

        @media screen and (max-width: 520px) {
            padding: 0px;
        }
    }

    .icons-wrap {
        display: flex;
        cursor: pointer;
    }

    .footer-wrap {
        color: $primary-blue;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        text-transform: capitalize;
        width: fit-content;
        margin: auto;
        cursor: pointer;
    }
}

.verifyEmailDialog {
    .verify-email-wrap {
        padding-top: 20px;

        .heading {
            font-size: 28px;
        }

        .email-wrap {
            color: $primary-black;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.1px;
            text-align: center;
        }

        .verifyOtp {
            margin: 48px 0px;
        }
    }
}

.forgotPasswordDialog {
    .MuiDialog-container {
        .MuiDialog-paper {
            margin: 0px 30px;
        }
    }
}