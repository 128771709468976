@import "../../styles/colors";

.signup-wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .heading {
    margin-top: 6vh;
  }

  .sub-heading {
    margin-top: 3vh;
    color: $tertiary-light-gray;
  }

  .signup-form {
    width: 34%;
    display: flex;
    flex-direction: column;
    grid-gap: 18px;

    .name-field {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      grid-gap: 18px;

      .first-name {
        width: 50%;
      }

      .last-name {
        width: 50%;
      }
    }

    .radio-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 9px;
      margin-top: 6vh;

      .radio-label {
        color: $primary-gray;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
      }

      .info-icon {
        margin-right: 15px;
      }
    }

    .terms-condition {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .agree-to {
      // display: flex;
      color: var(--main-color, $primary-black);
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      // justify-content: center;
      font-family: poppins;

      .t-c {
        color: var(--Primary-Color, $primary-blue);
        margin-left: 5px;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        text-decoration-line: underline;
      }

      .signin {
        font-size: 16px;
      }
    }
  }

  .account-exist {
    font-size: 16px !important;
  }

  .icons-wrap {
    display: flex;
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  .signup-wrap {
    .signup-form {
      .agree-to {
        font-size: 12px;

        .t-c {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .signup-wrap {
    .signup-form {
      width: 40%;
    }
  }
}

@media screen and (max-width: 600px) {
  .signup-wrap {
    padding: 20px;

    .heading {
      margin-top: 42px;
    }

    .page-heading {
      font-size: 20px;
      line-height: 20px;
    }

    .signup-form {
      width: 100%;


      .MuiFormGroup-root .radio-options {
        margin-right: 0px;
      }

      .agree-to {
        font-size: 12px;

        .t-c {
          font-size: 12px;
        }
      }
    }
  }
}

.MuiBox-root {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  // width: calc(100vw - 32px);

  .terms-modal {
    background-color: $primary-white;
    color: $primary-black;
    padding: 28px;
    display: flex;
    max-height: 620px;
    width: 546px;
    overflow: scroll;
    flex-direction: column;
    position: relative;

    .modal-heading {
      color: $primary-black;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      /* 100% */
    }

    .close-button {
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .title-wrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .title-heading {
        color: $primary-black;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        /* 150% */
        margin-top: 28px;
        margin-bottom: 14px;
      }

      .title-content {
        color: $primary-black;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        /* 150% */
      }
    }

    @media screen and (max-width: 650px) {
      max-height: 70%;
      overflow: scroll;
      width: auto;
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}