@import "../../styles/colors";

.education-page-wrap {
  background: $primary-white;
  padding: 28px 32px 22px 32px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-height: 600px;
  height: 100%;

  .education-header {
    display: flex;
    justify-content: space-between;

    .MuiButtonBase-root {
      width: 200px;
    }
  }

  .education-body-wrap {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  @media (max-width: 800px) {
    .education-header {
      .MuiButtonBase-root {
        width: 121px;
        height: 36px;
        font-size: 12px;
      }

      .edu-heading {
        font-size: 20px;
        display: flex;
        align-items: center;
      }
    }
  }

  @media (max-width: 700px) {
    max-height: 100%;
    padding: 12px 16px 0px 16px;
  }
}

.education-modal {
  // height: 600px !important;
  margin-bottom: -15px;

  .MuiModal-root {
    .MuiBox-root {
      height: 600px !important;
    }
  }
}

.educationDialog {
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiDialog-container {
    .MuiPaper-root {
      width: 500px;

    }
  }

  .education-modal {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .modal-title {
      display: flex;
      justify-content: center;
      color: $primary-black;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      /* 100% */
    }

    .employment-head {
      display: flex;
      justify-content: center;
    }

    .education-modal-wrap {
      display: flex;
      flex-direction: column;
      gap: 15px;
      // margin-bottom: 28px;

      .bottom-buttons {
        display: flex;
        flex-direction: row;
        gap: 20px;
      }

      .input-Label_text {
        color: $primary-grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        /* 100% */
      }

      .MuiFormControl-root {
        .MuiInputBase-root {
          .MuiInputBase-input {
            color: $primary-black;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            /* 100% */
          }
        }
      }
    }
  }
}

.deleteDialog {
  display: flex;
  justify-content: center;

  .MuiDialog-container {
    // height: 300px;
    padding: 0px 25px;

    .delete-dialog-wrap {
      display: flex;
      flex-direction: column;
      gap: 14px;

      .dialog-heading {
        color: $primary-black;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        /* 100% */
      }

      .dialog-content {
        color: $primary-gray;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        /* 150% */
        letter-spacing: 0.1px;
      }

      .bottom-buttons {
        display: flex;
        gap: 20px;
        margin-top: 28px;
      }
    }
  }
}

@media (max-width: 700px) {
  .educationDialog {
    .MuiDialog-container {
      width: 100%;

      .MuiPaper-root {
        width: 100%;
      }
    }
  }
}