@import '../../styles/colors';

.verification-wrap {
    max-height: calc(100vh - 76px);
    padding-top: 0px !important;
    overflow-y: auto;

    .myProfile-header {
        padding-top: 24px;
    }

    .content-wrap-main {
        @media screen and (max-width: 768px) {
            position: relative;

            .tabs-wrap-main {
                position: sticky;
                top: 0px;
                background: #ffffff;
                // width: calc(100% - 30px);
                z-index: 2;
            }

            .card-wrap-main {
                z-index: 1;
            }
        }
    }

    .verification-content-wrap {
        background: $primary-white;
        box-shadow: 0px 0px 2px 0px $shadow-color;
        // min-height: 82vh;
        margin-top: 24px;

        .cards-wrap {
            .card-wrap {
                display: flex;
                padding: 24px 32px;
                align-items: center;
                border-bottom: 1px solid $grey-border;

                @media screen and (max-width: 768px) {
                    align-items: flex-start;
                }

                @media screen and (max-width: 480px) {
                    padding: 16px 12px;
                }

                .profilePic-wrap {
                    // width: 76px;
                    // height: 76px;
                    width: 5vw;
                    height: 5vw;
                    display: flex;
                    border-radius: 146px;
                    border: 2px solid $secondary-grey-border;
                    margin-right: 18px;
                    overflow: hidden;

                    @media screen and (max-width: 768px) {
                        width: 8vw;
                        height: 8vw;
                    }

                    @media screen and (max-width: 480px) {
                        width: 42px;
                        height: 42px;
                    }

                    .profileImage {
                        width: inherit;
                    }
                }

                .right-content-wrap {
                    display: flex;

                    @media screen and (max-width: 768px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .name-wrap {
                        text-align: left;
                        max-width: 350px;
                        width: 350px;
                        margin-right: 10px;

                        @media screen and (max-width: 900px) {
                            width: 270px;
                        }

                        @media screen and (max-width: 768px) {
                            margin-right: 0px;
                            margin-bottom: 7px;
                            width: 100%;
                        }

                        .name {
                            color: $primary-black;
                            font-size: 24px;
                            font-weight: 600;
                            line-height: 24px;
                            margin-bottom: 8px;

                            @media screen and (max-width: 480px) {
                                font-size: 14px;
                            }
                        }

                        .email {
                            color: $primary-black;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 14px;

                            @media screen and (max-width: 480px) {
                                font-size: 12px;
                            }
                        }
                    }

                    .button-wrap {
                        @media screen and (max-width: 768px) {
                            display: none;
                        }
                    }

                    .status-wrap {
                        margin-left: 56px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        @media screen and (max-width: 900px) {
                            margin-left: 24px;
                        }

                        @media screen and (max-width: 768px) {
                            margin-left: 0px;
                        }

                        .verify-tag-wrap {
                            display: flex;
                            align-items: center;
                            border-radius: 12px;
                            background: $primary-green;
                            color: $primary-white;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 12px;
                            padding: 5px 12px;
                            margin-top: 8px;

                            .filterImage {
                                filter: brightness(2);
                            }
                        }
                    }
                }
            }

            .pending-card-wrap {
                .right-content-wrap {
                    .name-wrap {
                        max-width: 350px;
                        width: 24vw;
                        margin-right: 5.2vw;

                        @media screen and (max-width: 768px) {
                            margin-bottom: 13px;
                            width: 100%;
                        }
                    }

                    .status-wrap {
                        margin-left: 3.8vw;

                        @media screen and (max-width: 768px) {
                            margin-left: 0;
                        }

                        .pending-tag-wrap {
                            background: $primary-yellow;

                            @media screen and (max-width: 768px) {
                                margin-top: 0px;
                            }
                        }
                    }

                    .footer-wrap {
                        display: flex;

                        @media screen and (max-width: 768px) {
                            flex-direction: row-reverse;
                        }

                        .status-wrap {
                            @media screen and (max-width: 768px) {
                                margin: auto 20px auto 0px;
                            }
                        }
                    }
                }
            }

            .home-content-wrap {
                min-height: 76vh;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 480px) {
                    min-height: calc(100vh - 217px);
                }

                .no-data {
                    margin-top: 40px;
                    margin-bottom: 20px;
                    color: $primary-black;
                    font-size: 16px;
                    font-weight: 600;
                }

                .no-content {
                    color: $primary-gray;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
}

.verificationDetailDialog {
    .verificationDetail-content-wrap {
        width: 538px;

        @media screen and (max-width: 768px) {
            width: 70vw;
        }

        @media screen and (max-width: 480px) {
            width: 77vw;
        }

        .header-wrap {
            .heading {
                color: $primary-black;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
            }

            .subheading {
                color: $primary-gray;
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.1px;
                width: 345px;
                margin: auto;
                margin-top: 10px;

                @media screen and (max-width: 480px) {
                    width: 77vw;
                }
            }
        }

        .content-wrap {
            .profile-detail-wrap {
                display: flex;
                margin: 28px 0px;

                @media screen and (max-width: 480px) {
                    margin: 20px 0px;
                }

                .profile-pic-wrap {
                    width: 42px;
                    height: 42px;
                    border-radius: 146px;
                    border: 2px solid $secondary-grey-border;
                    margin-right: 16px;
                    overflow: hidden;

                    .profileImage {
                        width: 100%;
                        height: inherit;
                    }
                }

                .other-detail-wrap {
                    .name {
                        color: $primary-black;
                        font-size: 16px;
                        font-weight: 600;

                        @media screen and (max-width: 480px) {
                            font-size: 14px;
                        }
                    }

                    .email {
                        color: $primary-black;
                        font-size: 14px;
                        font-weight: 400;

                        @media screen and (max-width: 480px) {
                            font-size: 12px;
                            margin-top: 3px;
                        }
                    }
                }
            }

            .education-detail-wrap {
                background: $secondary-white;
                padding: 14px;
                margin-bottom: 8px;

                .heading {
                    color: $primary-gray;
                    font-size: 12px;
                    font-weight: 500;
                }

                .subheading {
                    color: $primary-black;
                    font-size: 14px;
                    font-weight: 600;
                }

                .description {
                    color: $primary-black;
                    font-size: 12px;
                    font-weight: 400;
                    word-break: break-word;
                    // hyphens: auto;
                }
            }
        }

        .footer-wrap {
            .comment-wrap {
                margin: 28px 0px;

                @media screen and (max-width: 480px) {
                    margin: 15px 0px;
                }
            }

            .button-wrap {
                display: flex;

                @media screen and (max-width: 480px) {
                    flex-direction: column-reverse;
                }
            }
        }
    }
}