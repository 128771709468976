@import '../../styles/colors';

.landing-content-wrap{
    background: $forth_light-gray;
    // height: 79vh;
    height: calc(100vh - 185px);
    margin: 0px 80px;
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px){
        margin: 0px 40px;
        margin-top: 30px;  
    }
    @media screen and (max-width: 480px){
        margin: 0px;
    }
    .heading{
        color: $primary-black;
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 2vh;
        padding-top: 7vh;
        @media screen and (max-width: 520px){
            font-size: 22px;
        }
    }
    .sepratorLine{
        background: $secondary-light-black;
        width: 172px;
        height: 1px;    
        margin: 0px auto;
    }
    .subheading{
        color: $primary-black;
        font-size: 20px;
        font-weight: 500;
        margin-top: 3vh;
        margin-bottom: 4vh;
        @media screen and (max-width: 520px){
            font-size: 16px;
        }
    }
    .description{
        color: $primary-black;
        font-size: 16px;
        font-weight: 500;
        @media screen and (max-width: 520px){
            font-size: 14px;
        }
    }
    .pb-5{
        @media screen and (max-width: 520px){
            padding-bottom: 5px;
        }
    }
    .link{
        color: $primary-blue;
        font-weight: 600;
        text-decoration: underline;
        margin-top: 3vh;
        cursor: pointer;
    }
    .button-wrap{
        display: flex;
        width: 380px;
        margin: auto;
        margin-top: 5vh;
        @media screen and (max-width: 550px){
            flex-direction: column;
            width: 80vw;
        }
    }
    .footer-wrap{
        display: flex;
        width: 480px;
        margin: 0px auto;
        margin-bottom: 6vh;
        @media screen and (max-width: 550px){
            flex-direction: column;
            width: -webkit-fill-available;
        }
        .tabs{
            font-weight: 500;
            margin: auto;
        }
    }
}

.aboutDialog{
    .about-wrap{
        padding: 0px !important;
        .heading{
            margin-bottom: 28px;
        }
        .subheading{
            color: $primary-black;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 14px;
        }
        .description{
            color: $primary-black;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 28px;
            line-height: 21px;
        }
        .email-wrap{
            background: $secondary-white;
            padding: 12px 44px;
            margin-bottom: 0px;
        }
        .centerAlign{
            text-align: center;
        }
    }
}