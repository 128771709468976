.container_text {
    text-align: start;
    font-size: 12px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    word-break: break-word;
    // hyphens: auto;
}

.container_wrapper {
    text-align: start;
}


.text {
    display: inline;
    width: 90%;
}

.read-or-hide {
    cursor: pointer;
    font-family: Poppins;
    font-size: 14px;
    line-height: 14px;
    color: #0B1F3E;
    font-weight: 500;
    text-decoration-line: underline;
    white-space: nowrap;
}