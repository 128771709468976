@import '../../styles/colors';

.dialogBox {
	.MuiBackdrop-root {
		background-color: $dialog_backdrop;
	}

	.MuiDialog-paper {
		background: $primary-white !important;
		box-shadow: 0px 7px 20px rgba(9, 9, 10, 0.15) !important;
		border-radius: 0px !important;
		// max-width: 400px;
		margin: 15px;

		.MuiDialogTitle-root {
			padding: 0 !important;
			text-align: center;

			.dialog-heading {
				display: flex;
				justify-content: space-between;
				padding: 15px !important;
				padding-bottom: 0 !important;
				position: relative;

				.dialog-title {
					font-weight: 700;
					font-size: 18px;
					line-height: 20px;
					color: $primary-black;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					padding-left: 19px;
					word-break: break-all;
				}

				.crossIcon {
					cursor: pointer;
					display: flex;
					align-items: center;
				}
			}

			img {
				width: 100%;
			}
		}

		.MuiDialogContent-root {
			padding: 28px;
			padding-top: 0px;

			@media screen and (max-width: 480px) {
				padding: 20px;
				padding-top: 0px;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.dialogBox {
		.MuiDialog-paper {
			.MuiDialogTitle-root {
				.dialog-heading {
					.dialog-title {
						padding: 0;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.dialogBox {
		.MuiDialog-paper {
			.MuiDialogTitle-root {
				.dialog-heading {
					.dialog-title {
						padding: 0;
						font-size: 20px;
					}
				}
			}
		}
	}
}