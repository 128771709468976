$primary-blue: #0B1F3E;
$primary-black: #252525;
$primary-light-black: #0000001a;
$secondary-light-black: #0000004D;
$primary-gray: #929292;
$primary-light-gray: #DFE1E6;
$Secondary-light-gray: #CCCCCC;
$tertiary-light-gray: #929292;
$forth_light-gray: #F2F2F2;
$fifth_light-gray: #EBEBEB;
$sixth_light_gray: #F5F5F5;
$primary-grey: #696F79;
$secondary-gray: #E2E2E2;
$tertiary-gray: #7A869A;
$dark-gray: #F5F5F5;
$forth-gray: #E4E4E4;
$primary-white: #FFFFFF;
$secondary-white: #F8F8F8;
$primary-red: #DE350B;
$normal-white: #e4e4e4;
$shadow-color: rgba(40, 41, 61, 0.14);
$grey-border: rgba(0, 0, 0, 0.1);
$secondary-grey-border: rgba(11, 31, 62, 0.25);
$tertiary-grey-border: rgba(11, 31, 62, 0.18);
$border-light-gray: #0B1F3E40;
$border-light-gray: #0B1F3E40;
$dialog_backdrop: #00000099;
$primary_green: #0cad39;
$secondary_border: rgba(0, 0, 0, 0.10);
$main_gradient: linear-gradient(180deg, #faf8f8 -13.98%, #d9d8d8 114.52%);
$primary-yellow: #C9AD18;
$background-grey: #8E8E8E;
$background-grey-color: #f8f8f8;
