@import "../../styles/colors";

.dashboard-wrap {
  position: relative;

  .myProfile-header {
    padding-top: 20px;
    padding-left: 45px;

    .bread-crum {
      color: var(--Primary-Color, $primary-blue);
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      /* 100% */
    }
  }

  .layout-header {
    position: fixed;
    width: -webkit-fill-available;
    top: 0;
    left: 0;
    border-bottom: 1px solid $primary-light-black;
    background: $primary-blue;
    text-align: left;
    padding: 16px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    @media screen and (max-width: 800px) {
      padding: 16px 25px;
    }

    @media screen and (max-width: 520px) {
      padding: 22px 15px;

      .logoImage {
        width: 153px;

        @media screen and (max-width: 350px) {
          width: 130px;
        }
      }
    }
  }

  .outer-content-wrap {
    background: $secondary-white;
    // margin-top: 37px;
    // min-height: calc(100vh - 64px);
    min-height: calc(100vh - 120px);
    display: flex;
    flex-direction: row;
    justify-content: center;

    .dashboard-layout-content-wrap {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      margin-top: 24px;
      width: 100%;
      margin-bottom: 100px;

      .sidebar-wrap {
        width: 25%;
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 25px;
      }

      .children-wrap {
        width: 65%;
        background: $primary-white;
      }

      @media (max-width: 700px) {
        margin-top: 0px;
        margin-bottom: 80px;

        .children-wrap {
          width: 100%;
        }

        .sidebar-wrap {
          display: none;
        }
      }
    }
  }
}