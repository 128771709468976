@import "../../styles/colors";
.text-icon {
  width: 15px;
  margin-right: 6px;
}
.custom-field {
  border-radius: 6px !important;
  margin-bottom: 10px !important;
  margin-top: 5px !important;

  .MuiInputBase-root {
    border-radius: 0px !important;
    background-color: $primary-white !important;
  }

  .MuiIconButton-root {
    margin-right: 0 !important;
    padding: 0 !important;
    width: 90px;
  }

  .MuiIconButton-root:hover {
    background-color: $primary-white !important;
  }

  .MuiFormControl-root {
    background-color: transparent !important;
    width: -webkit-fill-available !important;
  }

  .MuiInputBase-root {
    font-weight: 500 !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
  }

  .MuiOutlinedInput-input.Mui-disabled {
    background: $primary-white !important;
    border-radius: 6px !important;
  }

  .MuiOutlinedInput-input {
    padding: 15.5px !important;
    font-size: 16px !important;
    padding-left: 10px !important;
    color: $primary-black;
    font-size: 16px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
    line-height: 16px;
  }

  .MuiFormHelperText-root.Mui-error {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: $primary-red !important;
  }

  .MuiTouchRipple-root {
    outline: none !important;
    position: relative !important;
  }

  .MuiTouchRipple-root:focus-visible {
    outline: none !important;
    position: relative !important;
  }

  input:-webkit-autofill,
  .MuiInputBase-root:has(> input:-webkit-autofill) {
    transition-delay: 9999s;
  }
}
.info-icon {
  margin-left: 5px;
  cursor: pointer;
  margin-bottom: -7px;
  position: relative;
  bottom: 4px;
}

.disabledText {
  .MuiInputBase-root {
    background-color: $primary-white !important;
  }
}

.field-error {
  .MuiInputBase-root {
    border: none !important;
    border-radius: 0px !important;
  }
}

.input-Label_text {
  color: $primary-grey;
  font-weight: 400;
  text-align: left;
  font-size: 14px;
  line-height: 16px;
}

.textfield-error {
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: $primary-red !important;
  display: flex;
  text-align: left;

  div {
    img {
      width: 16px;
    }
    position: relative;

    span {
      position: relative;
      top: -4px;
    }
  }
}

.MuiTooltip-tooltip {
  /* Add your custom styles for the tooltip here */
  background-color: $primary-white; // Change the background color to white
  font-size: 14px;
  font-weight: 400;
  padding: 8px;
  border-radius: 4px; // Optional: Add border radius if desired

  & .MuiTooltip-tooltipArrow {
    background-color: $primary-white; // Change the background color to white
  }
}
