@import "../../styles/colors";
.total-score-wrap {
  border: 1px solid $grey-border;
  background: $main_gradient;
  border-left: 4px solid $primary-blue;
  display: flex;
  justify-content: space-between;
  padding: 24px 32px 23px 18px;
  .total-score-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 19px;
    .credibility-score-wrap {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
      .credebitlity-score {
        color: $primary-black;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px; /* 100% */
        display: flex;
      }
      .credibility-score-number {
        color: $primary-black;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px; /* 100% */
        display: flex;
        justify-content: flex-start;
      }
      @media (max-width: 900px){
        .credebitlity-score{
          font-size: 10px;
        }
        .credibility-score-number {
          font-size: 14px;
        }
      }
      @media (max-width: 700px){
        .credebitlity-score{
          font-size: 14px;
        }
        .credibility-score-number {
          font-size: 16px;
        }
      }
    }
    @media (max-width: 700px){
      .credibility-score-wrap{
        gap: 5px;
      }
    }
  }
  .total-score-right {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    .qualification-no {
      color: $primary-black;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px; /* 100% */
    }
    .qualification-value {
      display: flex;
      justify-content: flex-start;
      color: $primary-black;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px; /* 100% */
    }
    @media (max-width: 900px){
      .qualification-no {
        font-size: 10px;
      }
      .qualification-value {
        font-size: 14px;
      }
    }
    @media (max-width: 700px){
      .qualification-no {
        font-size: 14px;
      }
      .qualification-value {
        font-size: 16px;
      }
    }
  }
  @media (max-width: 700px){
    .total-score-right{
      grid-gap: 5px;
    }
  }
}

@media (max-width: 700px){
  .total-score-wrap{
    gap: 18px;
    flex-direction: column ;
    .total-score-left{
      .file-image{
        display: none;
      }
    }
  }
  .total-score-right{
    .qualification-no{
      display: flex;
      justify-content: flex-start;
    }
  }
}