@import '../../styles/colors';
.footer {
    background: $primary-white;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px 20px 30px;
   /* Add padding as needed */
    .sidebar-item {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      display: flex;
      height: 40px;
      align-items: center;
      color: $primary-gray;
      font-size: 12px;
    }
  
    .sidebar-item:hover {
      color: var(--Primary-Color, $primary-blue);
      font-weight: 600;
      line-height: normal;
      font-size: 12px;
    }
  .employment{
    margin-right: 60px;
  }
    .selected {
      color: var(--Primary-Color, $primary-blue);
      font-weight: 600;
      line-height: normal;
      font-size: 12px;

    }
  }
  @media (min-width: 700px){
    .footer{
      display: none;
    }
}