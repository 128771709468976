@import "../../styles/colors";

.listing-tile {
  .total-score-container {
    border: 1px solid $grey-border;
    background: $main_gradient;
    display: flex;
    justify-content: space-between;
    padding: 12px 21px 12px 25px;

    .total-score-left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      grid-gap: 19px;

      .credibility-score-wrap {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;

        .credebitlity-score {
          color: $tertiary-gray;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
        }

        .credibility-score-number {
          color: $primary-black;
          font-size: 20px;
          font-weight: 600;
          line-height: 20px;
          /* 100% */
          display: flex;
          justify-content: flex-start;
        }

        @media (max-width: 700px) {
          .credebitlity-score {
            font-size: 12px;
          }

          .credibility-score-number {
            font-size: 16px;
          }
        }
      }
    }

    .total-score-right {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      grid-gap: 10px;

      .qualification-no {
        color: $tertiary-gray;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
      }

      .qualification-value {
        border-radius: 12px;
        background: $primary_green;
        color: $primary-white;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        /* 100% */
        padding: 4px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 5px;
      }

      .qualification-source {
        border-radius: 12px;
        background: $primary-yellow;
        color: $primary-white;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        /* 100% */
        padding: 4px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 5px;
      }

      .qualification-self {
        border-radius: 12px;
        background: $background-grey;
        color: $primary-white;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        /* 100% */
        padding: 4px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 5px;
      }
    }

    @media (max-width: 700px) {
      .total-score-right {
        flex-direction: column;
      }
    }
  }

  .listing-title-body {
    background: $secondary-white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border: 1px solid $secondary_border;
    padding: 19px 24px 19px 24px;
    border-left: 4px solid $primary-blue;

    .listing-body-left {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 21px;

      @media (max-width: 700px) {
        .job-type {
          display: none;
        }

        .divider {
          display: none;
        }
      }

      .body-wrap {
        color: $primary-black;
        font-size: 20px;
        font-weight: 500;
        line-height: 16px;
        display: flex;
        grid-gap: 12px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      @media (max-width: 1300px) {
        .body-wrap {
          font-size: 14px;
        }
      }

      @media (max-width: 1100px) {
        .body-wrap {
          font-size: 12px;
        }
      }

      @media (max-width: 1100px) {
        .body-wrap {
          font-size: 12px;
          text-align: left;
        }
      }

      .body-content-source {
        color: $primary-black;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
      }

      .body-content-year {
        color: $primary-black;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
      }
    }

    .listing-body-right {
      display: flex;
      flex-direction: column;
      gap: 28px;
      min-width: 273px;

      .body-right-button {
        display: flex;
        justify-content: flex-end;

        // gap: 16px;
        .MuiButtonBase-root {
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
        }

        .secondary-button {
          margin-left: 16px;
        }
      }

      .body-right-content {
        color: $tertiary-gray;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        display: flex;
        justify-content: flex-end;

        .body-right-no {
          margin-left: 5px;
          color: $primary-black;
          font-size: 14px;
          font-weight: 600;
          line-height: 14px;
          /* 100% */
        }

        .primary-button {
          .MuiButtonBase-root {
            width: fit-content !important;
          }
        }

        .secondary-button {
          margin-left: 16px !important;

          .MuiButtonBase-root {
            width: fit-content !important;
          }
        }
      }

      @media (max-width: 1300px) {
        .body-right-content {
          justify-content: flex-end;
          font-size: 12px;

          .body-right-no {
            font-size: 12px;
          }
        }
      }

      @media (max-width: 1100px) {
        min-width: 200px;

        .body-right-content {
          justify-content: flex-end;
          font-size: 10px;

          .body-right-no {
            font-size: 10px;
          }
        }

        .body-right-button {
          flex-direction: column;
          align-items: flex-end;

          .MuiButtonBase-root {
            text-align: center !important;
            font-size: 12px !important;
            font-weight: 500 !important;
            line-height: 12px !important;
            width: 160px !important;
          }

          .secondary-button {
            margin-left: 0;
            margin-top: 16px;
          }
        }
      }

      @media (max-width: 700px) {
        .body-right-content {
          justify-content: flex-start;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 20px;
          font-size: 10px;

          .body-right-no {
            font-size: 10px;
            display: flex;
            justify-content: flex-start;
          }
        }

        .body-right-button {
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;

          .primary-button {
            .MuiButtonBase-root {
              text-align: center !important;
              font-size: 12px !important;
              font-weight: 500 !important;
              line-height: 12px !important;
              width: fit-content !important;
            }
          }

          .secondary-button {
            margin-left: 16px;
            margin-top: 0;

            .MuiButtonBase-root {
              text-align: center !important;
              font-size: 12px !important;
              font-weight: 500 !important;
              line-height: 12px !important;
              width: fit-content !important;
            }
          }
        }
      }


    }

    @media (max-width: 700px) {
      .listing-body-right {
        gap: 19px;
      }
    }
  }

  @media (max-width: 700px) {
    .listing-title-body {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.verification-modal-wrap {
  .verification-heading {
    color: $primary-black;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    /* 100% */
  }

  .employment-details {
    background: $background-grey-color;
    display: flex;
    flex-direction: column;
    padding: 14px;
    margin-top: 28px;
    margin-bottom: 28px;
    gap: 10px;
    color: $primary-gray;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;

    .organization {
      color: $primary-black;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      /* 114.286% */
    }

    .position {
      color: $primary-black;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      /* 116.667% */
    }
  }

  .note {
    color: $primary-gray;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    /* 150% */
    letter-spacing: 0.1px;
    margin-bottom: 12px;
  }

  .text-fields {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }
}