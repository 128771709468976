@import '../../styles/colors';

.tabs-wrap{
    .MuiTabs-root{
        border-bottom: 1px solid $primary-light-black;
        @media screen and (max-width: 480px) {
            margin-bottom: 12px;
        }
    }
    .MuiTabs-flexContainer{
        @media screen and (max-width: 480px) {
            justify-content: space-between;
        }
        .MuiTab-root{
            padding: 21px 60px;
            color: $primary-gray;
            font-family: 'Poppins';
            font-size: 20px;
            font-weight: 500;
            line-height: 20px;
            text-transform: capitalize; 
            @media screen and (max-width: 768px) {
                flex: 1;
            }
            @media screen and (max-width: 480px) {
                padding: 12px;
                flex: 1;
                font-size: 12px;
            }
        }
        .Mui-selected{
            color: $primary-blue;
            border-bottom: 3px solid $primary-blue;
        }
    }
}