body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300 400 500 600 700;
  src: url('../public/fonts/Poppins/Poppins-Regular.ttf');
}

.attach-icon{
  width: 15px;
  margin-right: 6px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  opacity: 15%;
  border-radius: 5px 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #283955;
  border-radius: 5px 5px;
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
